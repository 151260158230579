import React, { Component } from "react";
import { branding } from "global/settings";
import FrontPageLayout from "Components/Layout/FrontPageLayout";

const Home = () => {
  const config = {
    title: "Miller Hero",
    description:
      "The leading platform for the global farming industry to get information, share knowledge, stay in touch with the community, and ask questions.",
    links: [
      { title: "Join Community", link: branding.community.link },
      { title: "Industry News", link: "/news" },
    ],
    featureImage: "https://wiki.melvoridle.com/images/4/46/Farming_%28skill%29.svg",
  };
  return <FrontPageLayout {...config} />;
};

export default Home;
