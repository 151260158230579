import { Box, Typography } from "@mui/material";
import React, { Component } from "react";

const WidgetTitle = ({ text }) => {
  return (
    <Box
      mb={3}
      px={1}
      sx={{
        borderLeft: "4px solid #ddd",
      }}
    >
      <Typography variant="h6" textTransform="uppercase" fontWeight={600} letterSpacing={1}>
        {text}
      </Typography>
    </Box>
  );
};

export default WidgetTitle;
