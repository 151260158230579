import React, { Component } from "react";
import styled from "styled-components";

const Layout = styled.a`
  display: inline-block;
  padding: 5px 0;
  transition: all 0.3s;
  margin-top: 10px;
  color: #575757;
  &:hover {
    color: #000;
  }
`;

const BigLink = ({ link, title }) => {
  return (
    <Layout href={link || "#"} target="_blank">
      <div className="flexBox">
        <div>
          {title || "TITLE"}
          <i className="fa fa-chevron-right" style={{ marginLeft: "10px" }}></i>
        </div>
      </div>
    </Layout>
  );
};

export default BigLink;
