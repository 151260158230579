import { branding, global } from "global/settings";
import React, { Component } from "react";
import Media from "react-media";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo img {
    height: 80px;
    /* margin-right: 30px; */
  }

  ul {
    margin: 10px 0;
    display: flex;
    align-items: center;
  }

  .navBtn {
    list-style-type: none;
    margin-right: 20px;
    a {
      font-size: 0.9rem;
      transition: all 0.3s;
      text-transform: capitalize;
      color: #666;
    }

    a:hover {
      color: #000;
      cursor: pointer;
      border-bottom: 1px solid #333;
    }
  }

  a.selected {
    font-weight: 800;
    color: #000;
  }

  .mobile-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-shadow: var(--box-shadow-light);
    background: #ffffff;
    text-align: center;
    padding: 45px;
    z-index: 9999;
    .close-btn {
      position: absolute;
      right: 30px;
      color: #888;
      i {
        font-size: 1.4rem;
      }
    }
    li {
      padding: 10px 0;
      margin: 0;
    }
    .mobile-menu-logo {
      margin-bottom: 50px;
    }
  }
`;

class NavBar extends Component {
  state = {
    isMenuEclipsed: false,
  };

  render() {
    const { routes } = this.props;
    return (
      <Layout>
        {/* Small Screen */}
        <Media
          query={`(max-width: ${global.style.mobilescreenwidth})`}
          render={() => (
            <div className="container flexBox_between">
              <div className="logo">
                <nav>
                  <ul>
                    <img src={branding.logoimageurl} alt="" />
                  </ul>
                </nav>
              </div>
              <nav>
                <ul>
                  <i
                    onClick={() => this.setState({ isMenuEclipsed: !this.state.isMenuEclipsed })}
                    className="fa fa-bars"
                    style={{ marginRight: "10px" }}
                  ></i>
                </ul>
              </nav>
              {this.state.isMenuEclipsed && (
                <div className="mobile-menu" onClick={() => this.setState({ isMenuEclipsed: false })}>
                  <div className="close-btn">
                    <i className="fa fa-times"></i>
                  </div>
                  <nav>
                    <img className="mobile-menu-logo" src={branding.logoimageurl} alt="" width={"100"} />
                    {routes.map((ele, idx) => {
                      return (
                        <li key={idx} className="navBtn">
                          {ele.isExternal ? (
                            <a href={ele.link}>{ele.name}</a>
                          ) : (
                            <NavLink exact activeClassName={"selected"} to={ele.link}>
                              {ele.name}
                            </NavLink>
                          )}
                        </li>
                      );
                    })}
                    {branding.community.login === "" ? (
                      ""
                    ) : (
                      <li className="navBtn">
                        <a href={branding.community.login} target="_blank">
                          Login / Signup
                        </a>
                      </li>
                    )}
                  </nav>
                </div>
              )}
            </div>
          )}
        />
        {/* 
        Large Screen 
        */}
        <Media
          query={`(min-width: ${global.style.mobilescreenwidth})`}
          render={() => (
            <div className="container flexBox_between">
              <div className="logo">
                <nav>
                  <ul>
                    <img src={branding.logoimageurl} alt="" />
                  </ul>
                </nav>
              </div>
              <nav>
                <ul>
                  {routes.map((ele, idx) => {
                    return (
                      <li key={idx} className="navBtn">
                        {ele.isExternal ? (
                          <a href={ele.link}>{ele.name}</a>
                        ) : (
                          <NavLink exact activeClassName={"selected"} to={ele.link}>
                            {ele.name}
                          </NavLink>
                        )}
                      </li>
                    );
                  })}
                  {branding.community.login && (
                    <li className="navBtn">
                      <a href={branding.community.login} target="_blank">
                        Login / Signup
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          )}
        />
      </Layout>
    );
  }
}

export default NavBar;
