import FrontPageLayout from "Components/Layout/FrontPageLayout";
import HomePageComplexLayout from "Components/Layout/HomePageComplexLayout";
import { branding } from "global/settings";
import React, { Component } from "react";

const Home = () => {
  return <HomePageComplexLayout />;
};

export default Home;
