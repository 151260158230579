import { Typography } from "@mui/material";
import WidgetTitle from "Components/UI/Text/WidgetTitle";
import { branding } from "global/settings";
import React, { Component } from "react";
import styled from "styled-components";

const Layout = styled.div`
  color: #fff;
  background: var(--color-main);

  .menu-list {
    margin: 0 -10px -20px;
    & > li {
      display: inline-block;
      padding: 0 10px 20px;
      position: relative;
    }
    & > li > a {
      position: relative;
      color: #fff;
      letter-spacing: 0.5px;
    }
    & > li > a:hover {
      color: #d0d0d0;
    }
  }

  .social-links {
    a {
      color: #fff;
      font-size: 1rem;
      margin: 0 10px;
    }
  }

  .copyright {
    a {
      color: #fff;
    }
  }
`;

const Footer = ({ config }) => {
  return (
    <Layout className="footer">
      <div className="top-footer">
        <div className="container">
          <ul className="menu-list">
            <li>
              <a href={config.tc} target="_blank" rel="noopener noreferrer">
                Terms &amp; Conditions
              </a>
            </li>
            <li>
              <a href={config.pp} target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="main-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="widget">
                <h6 className="widget-title">{config.about.title}</h6>
                <p>{config.about.content}</p>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="widget">
                <h6 className="widget-title">Contact Us</h6>
                <p>
                  <a href={`mailto:${config.about.email}`}>
                    <Typography sx={{ color: "#fff" }}>{config.about.email}</Typography>
                  </a>
                </p>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="widget">
                  <h6 className="widget-title">Newsletter Sign Up</h6>
                  <p>Subscribe now and get exclusive news, interviews and stories.</p>
                  <form id="newsletter" className="newsletter">
                    <input type="email" name="newsletter-email" placeholder="Enter your email address" />
                    <button type="submit" data-type="submit" className="btn btn-style-4">
                      <i className="licon-mailbox-full" />
                      Subscribe
                    </button>
                  </form>
                </div>
              </div> */}
          </div>
        </div>
      </div>
      <div className="copyright">
        <span className="social-links">
          <a href={branding.social.facebook} className="social-icon">
            <i className="fab fa-facebook"></i>
          </a>
          <a href={branding.social.twitter} className="social-icon">
            <i className="fab fa-twitter"></i>
          </a>
          <a href={branding.social.linkedin} className="social-icon">
            <i className="fab fa-linkedin"></i>
          </a>
        </span>
        <p>
          Copyright © 2021 <a href="https://www.intelligon.com">Intelligon</a>. All Rights Reserved.
        </p>
      </div>
    </Layout>
  );
};

export default Footer;
