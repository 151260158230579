import NavBar from "Components/Common/NavBar";
import Blogs from "Components/Home/Blogs";
import Footer from "Components/Home/Footer";
import Main from "Components/Home/Main";
import MarketBar from "Components/Home/MarketBar";
import Container from "Components/Layout/Container";
import { branding } from "global/settings";
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./Home";

const routesMappings = [
  {
    name: "Home",
    link: "/",
  },
  // {
  //   name: "Articles",
  //   link: "/blogs",
  // },
  {
    name: "Info Hub",
    link: "/news",
  },
  {
    name: "Community",
    link: "https://community.millerhero.com",
    isExternal: true,
  },
];

export default class Layout extends Component {
  render() {
    return (
      <div id="wrapper" className="wrapper-container">
        <Router>
          <NavBar routes={routesMappings} />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/news">
              <Main />
            </Route>
            <Route exact path="/blogs">
              <Container inner={<Blogs />} />
            </Route>
            <Route exact path="/market">
              <Container inner={<MarketBar />}></Container>
            </Route>
            <Route
              path="*"
              render={() => {
                return <Home />;
              }}
            />
          </Switch>
        </Router>
        <Footer
          config={{
            tc: branding.meta.tc,
            pp: branding.meta.pp,
            about: {
              title: branding.meta.title,
              content: branding.meta.description,
            },
          }}
        />
      </div>
    );
  }
}
