import Footer from "Components/Home/Footer";
import Header from "Components/Layout/Header";
import { branding } from "global/settings";
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Home";

export default class Layout extends Component {
  render() {
    return (
      <div id="wrapper" className="wrapper-container">
        <Header />
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route
              path="*"
              render={() => {
                return <Home />;
              }}
            />
          </Switch>
        </Router>
        <Footer
          config={{
            tc: branding.meta.tc,
            pp: branding.meta.pp,
            about: {
              title: branding.meta.title,
              content: branding.meta.description,
              email: branding.contact.email,
            },
          }}
        />
      </div>
    );
  }
}
