import moment from "moment";

export function formatUtcTime(utcString, dateOnly) {
  const ts = moment(utcString);
  if (dateOnly) {
    return ts.format("DD MMM YYYY");
  } else {
    return ts.format();
  }
}
