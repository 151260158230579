import React, { Component } from "react";
import {
    TwitterTimelineEmbed
} from "react-twitter-embed";
import styled from "styled-components";
import { branding } from "global/settings";
import WidgetTitle from "Components/UI/Text/WidgetTitle";

const Layout = styled.div`

.event-card {
    margin: 10px 0;
    padding: 10px;
    font-size: 0.7rem;
    transition: background 0.5s;
    &:not(:last-child) {
        border-bottom: 1px solid #ddd;
    }
    &:hover {
        /* background:#eee */
    }
    .name {
        font-size: 1rem;
        font-weight: 500;
    }
}

`;

class ComingEvents extends Component {
    render() {
        return (
            <div>
                <WidgetTitle text="Coming Events" />
                {
                    branding.future_events.slice(0, 5).map((ele, idx) => (
                        <div className="event-card">
                            <div className="name"><a href={ele.link} target="_blank">{ele.name}</a></div>
                            <div className="date">{ele.date}</div>
                            <div className="location">{ele.location}</div>
                            <div className="body">{ele.theme}</div>
                        </div>
                    ))
                }
            </div>
        )
    }
}

// //////////// MAIN

export default class SidebarLeft extends Component {
    render() {
        return (
            <Layout className="sidebar sticky-bar col-lg-2 col-md-12">
                <div className="widget">
                    <WidgetTitle text="Latest Tweets" />
                    <TwitterTimelineEmbed
                        sourceType="list"
                        id={branding.twitterListId}
                        options={{ height: 700 }}
                    />
                </div>
                <div className="widget">
                    <ComingEvents />
                </div>
            </Layout>

        );
    }
}
