import React, { Component } from "react";
import logoPath from "images/branding/water_central_wbg-logo.png";
import { branding } from "global/settings";
import FrontPageLayout from "Components/Layout/FrontPageLayout";

const Home = () => {
  const config = {
    title: "Water Central",
    description:
      "The leading platform for the water resource and trading market to get information, share knowledge, stay in touch with the community, and ask questions.",
    links: [{ title: "Join Community", link: branding.community.link }],
    featureImage: logoPath,
  };
  return <FrontPageLayout {...config} />;
};

export default Home;
