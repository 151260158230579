import { colors } from "@mui/material";
import React from "react";

const DollarFormat = ({ value, withColor, decimal }) => {
  if (value === undefined) return "";
  return (
    <span
      style={
        withColor && {
          color: value > 0 ? colors.green[400] : value < 0 ? colors.red[400] : colors.grey[800],
        }
      }
    >
      ${decimal ? value.toFixed(decimal) : value}
    </span>
  );
};

export default DollarFormat;
