import React from "react";
import { Box } from "@mui/material";
import WidgetTitle from "Components/UI/Text/WidgetTitle";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { branding } from "global/settings";

export const TwitterWidget = () => {
  return (
    <Box>
      <WidgetTitle text="Tweets" />
      <TwitterTimelineEmbed sourceType="list" id={branding.twitterListId} options={{ height: 700 }} />
    </Box>
  );
};
