import { get_wordpress_blogs } from "Api";
import WidgetTitle from "Components/UI/Text/WidgetTitle";
import React, { Component } from "react";
import { branding } from "global/settings";
import styled from "styled-components";
import { formatUtcTime } from "helpers/helpers";

const Layout = styled.div`
  .post-wrapper {
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: #ddd solid 1px;
  }

  .post-wrapper:last-child {
    border-bottom: none;
  }

  .post-title {
    font-size: 1.8rem;
    font-weight: 800;
    a {
      font-weight: 800;
      color: unset;
    }
  }

  .post-body {
    display: flex;
  }

  .post-excerpt {
    max-width: 800px;
    font-size: 0.9rem;
    color: #666;
  }

  .post-excerpt-link {
    font-size: 1rem;
    margin-top: 10px;
    font-weight: 800;
  }

  .post-feat-image {
    min-width: 270px;
    height: 200px;
    margin-right: 30px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    box-shadow: var(--box-shadow-light);
  }

  .post-created {
    font-size: 1rem;
    font-weight: 800;
    letter-spacing: 1px;
    color: var(--color-main);
  }

  @media only screen and (max-width: 728px) {
    .post-body {
      flex-direction: column;
    }
    .post-feat-image {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

class Blogs extends Component {
  state = {
    list: [],
  };

  componentDidMount() {
    if (branding.wordpress) {
      get_wordpress_blogs(branding.wordpress.categoryId).then((res) => {
        // console.log(res.data);
        this.setState({ list: res.data.slice(0, 10) });
      });
    }
  }

  render() {
    return (
      <Layout>
        <WidgetTitle text="Feature Articles" />
        {this.state.list.length === 0 ? (
          <div className="post-wrapper">
            <span>Coming Soon...</span>
          </div>
        ) : (
          this.state.list.map((ele) => {
            return (
              <div className="post-wrapper">
                <div className="post-body">
                  <div
                    className="post-feat-image"
                    style={{
                      backgroundImage: `url("${ele.jetpack_featured_media_url}")`,
                    }}
                  ></div>
                  <div>
                    <p className="post-created">{formatUtcTime(ele.date, 1)}</p>
                    <p className="post-title">
                      <a href={ele.link} target="_blank">
                        {ele.title.rendered}
                      </a>
                    </p>
                    <p className="post-excerpt">
                      <span dangerouslySetInnerHTML={{ __html: ele.excerpt.rendered }} />
                    </p>
                    <p className="post-excerpt-link">
                      <span>
                        <a href={ele.link} target="_blank">
                          Read Full Article
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </Layout>
    );
  }
}

export default Blogs;
