import axios from "axios";
import _ from "lodash";

export const get_news = (tag) => {
  tag = tag || "shrimpstar";
  const API = `https://api.intelligon.com/news?tag=${tag}`;
  return axios.get(API);
};

export const get_prices = () => {
  const API = `https://api.intelligon.com/timeseries/tip`;
  return axios.get(API);
};

export const get_wordpress_blogs = (categoryId) => {
  const API = `https://blogs.intelligon.com/wp-json/wp/v2/posts?categories=${categoryId}`;
  return axios.get(API);
};

export const DataApi = {
  Shrimpstar: {
    tipdata: () =>
      axios
        .get("https://api.intelligon.com/timeseries/tip")
        .then((res) => res.data.payload)
        .catch((err) => console.log(err)),
  },
  Intelligon: {
    get_wordpress_blogs: (categoryId) =>
      axios
        .get(`https://blogs.intelligon.com/wp-json/wp/v2/posts?categories=${categoryId}`)
        .then((res) => {
          return _.take(res.data, 10);
        })
        .catch((err) => console.log(err)),
  },
};
