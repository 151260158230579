import { Box, colors, Typography } from "@mui/material";
import { branding } from "global/settings";
import moment from "moment";
import React from "react";
import FlexBox from "./FlexBox";

const Header = () => {
  return (
    <Box p={2}>
      <FlexBox justifyContent="center">
        <a href="/">
          <img src={branding.logoimageurl} width="150" alt="" />
        </a>
      </FlexBox>
    </Box>
  );
};

export default Header;
