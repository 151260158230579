const DEFAULT_APP_VERSION = "shrimpstar";

export const REACT_APP_VERSION = process.env.REACT_APP_VERSION
  ? process.env.REACT_APP_VERSION.trim()
  : DEFAULT_APP_VERSION;

export const branding = {
  shrimpstar: {
    meta: {
      title: "ShrimpStar: The leading platform for the global shrimp aquaculture industry",
      description:
        "The leading platform for the global shrimp aquaculture industry to get information, share knowledge, stay in touch with the community, and ask questions.",
      googleAnlyticsId: "G-N78H3JKNW1",
      keywords: "shrimp,aquaculture,shrimpstar,global shrimp business,agriculture,agriculturetechnology",
      url: "https://www.shrimpstar.com",
      domain: "https://shrimpstar.com",
      image: "https://blogs.intelligon.com/wp-content/uploads/2022/03/shrimpstar-og.jpg",
      tc: "https://intelligon-public.s3-ap-southeast-2.amazonaws.com/ShrimpStar-website-terms-and-conditions.pdf",
      pp: "https://intelligon-public.s3-ap-southeast-2.amazonaws.com/Shrimpstar-website-privacy-policy.pdf",
    },
    wordpress: {
      categoryId: 4,
    },
    style: {
      themeColor: "rgb(94,37,11)",
      minorColor: "rgb(112, 43, 11)",
      backgroundColor: "#FFFFFF",
    },
    logoimageurl: "https://intelligon-public.s3-ap-southeast-2.amazonaws.com/branding/Shrimpstar++tailed.png",
    community: {
      link: "https://community.shrimpstar.com/",
      login: "https://community.shrimpstar.com/entry/signin?target=https%3A%2F%2Fcommunity.shrimpstar.com%2F",
      signup: "https://community.shrimpstar.com/entry/register?target=https%3A%2F%2Fcommunity.shrimpstar.com%2F",
    },
    social: {
      facebook: "https://www.facebook.com/shrimpstars",
      twitter: "https://twitter.com/ShrimpStars",
      linkedin: "https://www.linkedin.com/company/shrimpstar-com",
    },
    market: {
      title: "Pacific White Shrimp",
      items: [
        { label: "Ecuador, Guayas", value: "6753-40" },
        { label: "Indonesia, East Java", value: "6382-40" },
        { label: "Philippines, Central Philippines", value: "8657-40" },
        { label: "Thailand, Chantaburi", value: "6468-40" },
        { label: "Vietnam, Mekong Delta", value: "6371-30" },
      ],
    },
    future_events: [
      {
        name: "Fenacam",
        location: "Brazil",
        date: "16-19 November 2021",
        theme: "",
        link: "http://www.fenacam.com.br/",
      },
    ],
    twitterListId: "1341549798248173569",
    contact: {
      email: "info@shrimpstar.com",
    },
  },
  millerhero: {
    meta: {
      title: "Millerhero",
      description:
        "At Intelligon, our mission is to realise the full potential of innovative technologies, to provide centralised real time insights for commercial operations in agriculture, aquaculture and food supply chains, driving a new era of development, growth and productivity. Intelligon centralises data capture, transformation, processing and analytics. Structured and unstructured data is transformed for analysis through the Intelligon platform to formulate real time, actionable, commercial insights.",
      googleAnlyticsId: "G-EJNDSF8EHB",
      tc: "",
      pp: "",
    },
    style: {
      themeColor: "#156432",
      minorColor: "#267744",
    },
    logoimageurl: "https://intelligon-public.s3-ap-southeast-2.amazonaws.com/branding/Miller-Hero.png",
    community: {
      link: "https://community.millerhero.com/",
      login: "https://community.millerhero.com/entry/signin",
      signup: "https://community.millerhero.com/entry/register",
    },
    social: {
      facebook: "",
      twitter: "",
      linkedin: "",
    },
    market: {
      title: "Global Wheat Price",
      items: [],
    },
    future_events: [],
    twitterListId: "1369956828012892169",
  },
  watercentral: {
    meta: {
      title: "Water Central",
      description:
        "At Intelligon, our mission is to realise the full potential of innovative technologies, to provide centralised real time insights for commercial operations in agriculture, aquaculture and food supply chains, driving a new era of development, growth and productivity. Intelligon centralises data capture, transformation, processing and analytics. Structured and unstructured data is transformed for analysis through the Intelligon platform to formulate real time, actionable, commercial insights.",
      googleAnlyticsId: "G-9T03JHXLV1",
      tc: "",
      pp: "",
    },
    style: {
      themeColor: "#25AAE1",
      minorColor: "#25AAE1",
    },
    logoimageurl: "https://intelligon-public.s3.ap-southeast-2.amazonaws.com/branding/water_central_wbg-logo-text.png",
    community: {
      link: "https://community.watercentral.com/",
      login: "https://community.watercentral.com/entry/signin",
      signup: "https://community.watercentral.com/entry/register",
    },
    social: {
      facebook: "",
      twitter: "",
      linkedin: "",
    },
    market: {
      title: "Global Wheat Price",
      items: [],
    },
    future_events: [],
    twitterListId: "1369956828012892169",
  },
}[REACT_APP_VERSION];

export const global = {
  style: {
    font: `Inter, Helvetica, Arial`,
    mobilescreenwidth: "768px",
  },
};

export const CSSROOT = `
:root {
    --font-main: ${global.style.font};
    --color-main: ${branding.style.themeColor};
    --color-minor: ${branding.style.minorColor};
    --color-background: ${branding.style.backgroundColor};
    --color-front: #fff;
    --box-shadow-light: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    --mobile-screen-width: "${global.style.mobilescreenwidth}";
}
`;
