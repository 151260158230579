import { Box, Button, Typography } from "@mui/material";
import WidgetTitle from "Components/UI/Text/WidgetTitle";
import { branding } from "global/settings";
import React from "react";

export const CommunityWidget = () => {
  return (
    <Box>
      <WidgetTitle text="Community" />
      <Box my={2}>
        <Box py={1}>
          <Typography>Join the community to share knowledge, discuss topics and ask questions online.</Typography>
        </Box>
        <a href={branding.community.link} target="_blank">
          <Button fullWidth variant="outlined" size="large">
            Join the Community
          </Button>
        </a>
      </Box>
    </Box>
  );
};
