import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import { get_news } from "Api";
import { REACT_APP_VERSION } from "global/settings";
import WidgetTitle from "Components/UI/Text/WidgetTitle";

const Layout = styled.div`
  .shareBtnLayout {
    margin: 10px 0;
  }
  .shareBtnLayout > a {
    margin-right: 10px;
  }
`;

const News = () => {
  const [news, setNews] = useState([]);

  const update_news = () =>
    get_news(REACT_APP_VERSION).then((res) => {
      const news = res.data.payload.map((ele) => ({
        ...ele,
        pubBy: ele.title.split(" - ")[1],
        title: ele.title.split(" - ")[0],
      }));
      setNews(news);
    });

  useEffect(() => {
    update_news();
  }, []);

  return (
    <Layout className="main col-lg-8 col-md-12 lside">
      <WidgetTitle text="Internet News" />
      <div className="content-element4">
        <div className="entry-box row">
          {news.map((ele, idx) => (
            <div key={idx} className="col-md-12">
              <div className="entry entry-small">
                <div className="entry-body">
                  <a href={ele.link} target="_blank">
                    {/* <a className="entry-label">{ele.keyword}</a> */}
                    <h5 className="entry-title">{ele.title}</h5>
                    <div className="entry-meta">
                      {ele.pubBy} | {ele.pubdate.split(" ")[0]}
                    </div>
                  </a>
                  <div className="flexBox shareBtnLayout">
                    <span style={{ fontSize: "0.8rem", marginRight: "10px" }}>Share on: </span>
                    <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${ele.link}`}>
                      <div className="iconBtn">
                        <i className="fab fa-facebook" />
                      </div>
                    </a>
                    <a target="_blank" href={`https://twitter.com/intent/tweet?url=${ele.link}&via=ShrimpStar`}>
                      <div className="iconBtn">
                        <i className="fab fa-twitter" />
                      </div>
                    </a>
                    <a
                      target="_blank"
                      href={`https://www.linkedin.com/shareArticle/?&url=${ele.link}&source=https://shrimpstar.com/`}
                    >
                      <div className="iconBtn">
                        <i className="fab fa-linkedin" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default News;
