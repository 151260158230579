import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { DataApi } from "Api";
import FlexBox from "Components/Layout/FlexBox";
import WidgetTitle from "Components/UI/Text/WidgetTitle";
import { branding } from "global/settings";
import { formatUtcTime } from "helpers/helpers";
import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";

const BlogsGrid = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const initData = async () => {
      const result = await DataApi.Intelligon.get_wordpress_blogs(branding.wordpress.categoryId);
      setBlogs(result);
    };
    initData();
  }, []);

  if (blogs.length < 1)
    return (
      <Box>
        <WidgetTitle text="Blogs" />
        <Stack spacing={2}>
          <Skeleton variant="rectangular" width={"100%"} height={120} />
          <Skeleton variant="rectangular" width={"100%"} height={20} />
          <Divider />
          <Stack direction="row" spacing={2}>
            <Skeleton variant="rectangular" width={100} height={70} />
            <Skeleton variant="rectangular" width={"100%"} height={20} />
          </Stack>
          <Divider />
          <Stack direction="row" spacing={2}>
            <Skeleton variant="rectangular" width={100} height={70} />
            <Skeleton variant="rectangular" width={"100%"} height={20} />
          </Stack>
          <Divider />
          <Stack direction="row" spacing={2}>
            <Skeleton variant="rectangular" width={100} height={70} />
            <Skeleton variant="rectangular" width={"100%"} height={20} />
          </Stack>
        </Stack>
      </Box>
    );

  const featureBlog = _.first(blogs);
  const restBlogs = _.drop(blogs, 1);

  return (
    <Box>
      <WidgetTitle text="Blogs" />
      <a href={featureBlog.link} target="_blank">
        <Box my={2}>
          <Typography
            variant="h5"
            lineHeight={1}
            sx={{ fontSize: "1.8rem", "&:hover": { color: branding.style.themeColor } }}
          >
            <strong>{featureBlog.title.rendered}</strong>
          </Typography>
          <Box
            my={2}
            sx={{
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundImage: `url("${featureBlog.jetpack_featured_media_url}")`,
              minWidth: 220,
              height: 220,
              borderRadius: 2,
            }}
          ></Box>
          <Typography variant="caption">
            <span dangerouslySetInnerHTML={{ __html: featureBlog.excerpt.rendered }} />
          </Typography>
        </Box>
      </a>
      <Divider variant="middle" />
      <Stack spacing={1} my={2}>
        {restBlogs.map((blog, idx) => {
          return (
            <a key={idx} href={blog.link} target="_blank">
              <FlexBox sx={{ alignItems: "flex-start" }}>
                <Box
                  sx={{
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundImage: `url("${blog.jetpack_featured_media_url}")`,
                    minWidth: 100,
                    minHeight: 70,
                    borderRadius: 1,
                  }}
                ></Box>
                <Box ml={2}>
                  <Typography
                    sx={{ fontSize: "1rem", fontWeight: "600", "&:hover": { color: branding.style.themeColor } }}
                  >
                    {blog.title.rendered}
                  </Typography>
                </Box>
              </FlexBox>
            </a>
          );
        })}
      </Stack>
    </Box>
  );
};

export default BlogsGrid;
