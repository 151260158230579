import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import { get_news } from "Api";
import { REACT_APP_VERSION } from "global/settings";
import WidgetTitle from "Components/UI/Text/WidgetTitle";
import { Box, Divider, Stack, Typography, Skeleton } from "@mui/material";
import _ from "lodash";

const News = () => {
  const [news, setNews] = useState([]);

  const update_news = () =>
    get_news(REACT_APP_VERSION).then((res) => {
      const news = res.data.payload.map((ele) => ({
        ...ele,
        pubBy: ele.title.split(" - ")[1],
        title: ele.title.split(" - ")[0],
      }));
      setNews(_.take(news, 12));
    });

  useEffect(() => {
    update_news();
  }, []);

  return (
    <Box>
      <WidgetTitle text="Industry News" />
      <Stack spacing={2}>
        {news.length < 1 && (
          <Box>
            <Stack spacing={2}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
              <Skeleton variant="rectangular" width={"100%"} height={20} />
              <Divider />
              <Skeleton variant="rectangular" width={"100%"} height={60} />
              <Skeleton variant="rectangular" width={"100%"} height={20} />
              <Divider />
              <Skeleton variant="rectangular" width={"100%"} height={60} />
              <Skeleton variant="rectangular" width={"100%"} height={20} />
            </Stack>
          </Box>
        )}
        {news.map((ele, idx) => (
          <Box>
            <a href={ele.link} target="_blank">
              <Typography sx={{ fontSize: "1rem", fontWeight: "600" }} lineHeight="18px">
                {ele.title}
              </Typography>
            </a>
            <Stack direction="row" alignItems="center" spacing={1} my={1}>
              <Typography variant="caption">
                <strong>{ele.pubBy}</strong> {ele.pubdate.split(" ")[0]}
              </Typography>
              {/* <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${ele.link}`}>
                <div className="iconBtn">
                  <i className="fab fa-facebook" />
                </div>
              </a>
              <a target="_blank" href={`https://twitter.com/intent/tweet?url=${ele.link}&via=ShrimpStar`}>
                <div className="iconBtn">
                  <i className="fab fa-twitter" />
                </div>
              </a>
              <a
                target="_blank"
                href={`https://www.linkedin.com/shareArticle/?&url=${ele.link}&source=https://shrimpstar.com/`}
              >
                <div className="iconBtn">
                  <i className="fab fa-linkedin" />
                </div>
              </a> */}
            </Stack>
            <Divider />
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default News;
