import React from "react";
import BigLink from "Components/UI/Buttons/BigLink";
import FrontPageTitle from "Components/UI/Text/FrontPageTitle";
import { branding } from "global/settings";
import FadeIn from "react-fade-in";
import styled from "styled-components";

const Layout = styled.div`
  section.main {
    min-height: 90vh;
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    position: relative;

    p {
      margin: 20px 0;
    }

    .main-left {
      z-index: 100;
      max-width: 60%;
    }

    .main-right {
      float: right;
    }
  }

  @keyframes floatUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .imageWrapper {
    opacity: 0;
    animation: floatUp 1s;
    animation-delay: 500ms;
    animation-fill-mode: forwards;
  }

  @media screen and (max-width: 992px) {
    padding: 0 20px;
    section.main {
      .main-left {
        max-width: 100%;
        background-color: #ffffffbc;
        padding: 150px 20px;
        border-radius: 20px;
        text-align: center;
      }
    }
    .imageWrapper {
      position: absolute;
      opacity: 0;
      top: 20%;
      animation: floatUp 1s;
      animation-delay: 500ms;
      animation-fill-mode: forwards;
    }
  }
`;

const FrontPageLayout = ({ title, description, links, featureImage }) => {
  return (
    <Layout className="container">
      <section className="main">
        <div className="main-left">
          <FadeIn delay={100} transitionDuration={1000}>
            <FrontPageTitle text={title} color={branding.style.themeColor} />
            <p>{description}</p>
            {links.map((ele, idx) => (
              <BigLink key={idx} title={ele.title} link={ele.link} />
            ))}
          </FadeIn>
        </div>
        <div className="imageWrapper">
          <img src={featureImage} alt="" width="500" />
        </div>
      </section>
    </Layout>
  );
};

export default FrontPageLayout;
