import React, { Component } from "react";
import Blogs from "./Blogs";

import News from "./layout/News";
import MarketBar from "./MarketBar";
import Sidebar from "./Sidebar";

export default class Main extends Component {
  render() {
    return (
      <div id="content" className="page-content-wrap2 no-tps">
        <div className="container">
          <div className="content-element5">
            <div className="row">
              <main id="main" className="col-md-12">
                <div className="row no-gutters">
                  <News />
                  <Sidebar />
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
