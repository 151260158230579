import React, { Component } from "react";
import styled from "styled-components";

const Layout = styled.div`
  font-size: 5.5rem;
  font-weight: 800;
  margin: 10px 0;
  white-space: nowrap;
  @media screen and (max-width: 992px) {
    font-size: 3rem;
  }
  ${(p) => p.color && `color: ${p.color}`}
`;

const FrontPageTitle = ({ text, color }) => {
  return <Layout color={color}>{text}</Layout>;
};

export default FrontPageTitle;
