import { Box, Grid } from "@mui/material";
import BlogsGrid from "Components/Widgets/BlogsGrid";
import { CommunityWidget } from "Components/Widgets/CommunityWidget";
import { MarketStrip } from "Components/Widgets/MarketStrip";
import News from "Components/Widgets/News";
import { TwitterWidget } from "Components/Widgets/TwitterWidget";
import React from "react";

/* 

The complex homepage layout consists of market view, news section, blog section and tweet section

*/
export default function HomePageComplexLayout({}) {
  return (
    <Box
      py={2}
      px={4}
      sx={{
        maxWidth: "1440px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Box>
        <MarketStrip />
        <Grid container mt={2} spacing={4} mb={12}>
          <Grid item xs={12} md={3} lg={3}>
            <News />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <BlogsGrid />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CommunityWidget />
            <TwitterWidget />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
