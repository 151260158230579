import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import "css/bootstrap-grid.min.css";
import "css/theme-style.css";
import "css/responsive.css";
import "css/custom.css";

import ShrimpstarLayout from "brands/shrimpstar/Layout";
import MillerheroLayout from "brands/millerhero/Layout";
import WatercentralLayout from "brands/watercentral/Layout";
import { CSSROOT, REACT_APP_VERSION } from "global/settings";
import { branding } from "global/settings";
import ReactGA from "react-ga";

if (branding.meta.googleAnlyticsId) {
  ReactGA.initialize(branding.meta.googleAnlyticsId);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const app_store = (version) => {
  if (!version) {
    version = "shrimpstar";
  }
  return {
    shrimpstar: <ShrimpstarLayout />,
    millerhero: <MillerheroLayout />,
    watercentral: <WatercentralLayout />,
  }[version];
};

export default class Home extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{branding.meta.title}</title>
          <meta name="description" content={branding.meta.description}></meta>
          <meta name="keywords" content={branding.meta.keywords} />

          <meta property="og:title" content={branding.meta.title}></meta>
          <meta property="og:description" content={branding.meta.description}></meta>
          <meta property="og:url" content={branding.meta.url}></meta>

          <meta name="twitter:title" content={branding.meta.title}></meta>
          <meta name="twitter:description" content={branding.meta.description}></meta>
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta name="twitter:image" content={branding.meta.image}></meta>
          <meta property="twitter:url" content={branding.meta.url}></meta>

          <style>{CSSROOT}</style>

          {/* Global site tag (gtag.js) - Google Analytics */}
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${branding.meta.googleAnlyticsId}`} />
          <script>{`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "${branding.meta.googleAnlyticsId}");
          `}</script>
          <link
            rel="icon"
            href={`https://intelligon-public.s3-ap-southeast-2.amazonaws.com/branding/${REACT_APP_VERSION}.ico`}
          />
        </Helmet>
        {app_store(REACT_APP_VERSION)}
      </Fragment>
    );
  }
}
