import { Box, colors, Typography } from "@mui/material";
import { DataApi } from "Api";
import DollarFormat from "Components/Text/DollarFormat";
import { branding } from "global/settings";
import React, { useEffect, useState } from "react";
import { Chart as ChartJS } from "chart.js/auto";
import { Chart } from "react-chartjs-2";
import FlexBox from "Components/Layout/FlexBox";
import _ from "lodash";

const TITLE = branding.market.title;
const OPTIONS = branding.market.items;

const MarketCard = ({ region, country, wow, weight, filteredData }) => {
  const lastprice = (_.last(filteredData) || {})["price_usd"];
  return (
    <Box px={2} sx={{ flex: 1 }}>
      <FlexBox>
        <Box sx={{ minWidth: "100px" }}>
          <Typography variant="caption" component="div" lineHeight={1}>
            {region}
          </Typography>
          <Typography variant="caption" component="div" lineHeight={1}>
            <strong>{country}</strong>
          </Typography>
          <Typography variant="body1" component="div" sx={{ marginBottom: "-8px" }}>
            <DollarFormat value={lastprice} decimal={2} />
          </Typography>
          <Box>
            <Typography variant="caption" lineHeight={1}>
              <DollarFormat value={wow} decimal={2} withColor />
            </Typography>
          </Box>
          <Typography variant="caption" component="div" lineHeight={1}>
            {weight} HOSO/kg
          </Typography>
        </Box>
        <Box ml={2}>
          <Chart
            type="line"
            width={110}
            height={80}
            options={{
              title: {
                display: false,
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                  ticks: {
                    display: false,
                  },
                },
                y: {
                  grid: {
                    display: false,
                  },
                  tick: {
                    display: false,
                  },
                },
              },
            }}
            data={{
              labels: filteredData.map((ele) => ele.date),
              datasets: [
                {
                  fill: true,
                  pointRadius: 0,
                  borderColor: wow > 0 ? colors.green[400] : colors.red[400],
                  backgroundColor: wow > 0 ? colors.green[50] : colors.red[50],
                  data: filteredData.map((ele) => ele.price_usd),
                },
              ],
            }}
          />
        </Box>
      </FlexBox>
    </Box>
  );
};

export const MarketStrip = () => {
  const [marketData, setMarketData] = useState([]);

  useEffect(() => {
    const initData = async () => {
      const data = await DataApi.Shrimpstar.tipdata();
      const marketData = OPTIONS.map((ele) => {
        const dataid = ele.value;
        const filteredData = data.filter((item) => item.dataid === dataid);
        if (filteredData.length === 0) {
          return null;
        }
        const country = filteredData[0].country;
        const region = filteredData[0].region;
        const weight = filteredData[0].weight;
        const lastprice = filteredData[filteredData.length - 1]["price_usd"];
        const wow =
          filteredData[filteredData.length - 1]["price_usd"] - filteredData[filteredData.length - 2]["price_usd"];
        return {
          dataid,
          country,
          region,
          weight,
          lastprice,
          wow,
          filteredData,
        };
      }).filter((ele) => ele);
      setMarketData(marketData);
    };
    initData();
  }, []);

  return (
    <Box
      p={1}
      sx={{ display: "flex", alignItems: "center", background: colors.grey[50], overflow: "auto", borderRadius: 2 }}
    >
      {marketData.map((ele, idx) => (
        <MarketCard key={idx} {...ele} />
      ))}
    </Box>
  );
};
