import React, { Component } from 'react';

class Container extends Component {
  render() {
    return (
      <div className="container" style={{paddingBottom: "50px"}}>
        {this.props.inner}
      </div>
    );
  }
}

export default Container;