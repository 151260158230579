import React, { Component } from "react";
import styled from "styled-components";
import { get_prices } from "Api";
import { Chart as ChartJS } from "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { branding } from "global/settings";
import WidgetTitle from "Components/UI/Text/WidgetTitle";

const Layout = styled.div`
  h1 {
    color: #111;
  }

  .market-wrapper {
    overflow-x: auto;
    padding: 10px;
    box-sizing: border-box;
    .market-card {
      flex: 0 1 32%;
      margin: 0 10px;
      border-radius: 20px;
      background: #ffffff;
      transition: background 0.4s;
      padding: 20px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .chart-wrapper {
        margin: 10px 0;
        width: 300px;
      }
      &:hover {
      }
    }
  }
`;

const TITLE = branding.market.title;
const OPTIONS = branding.market.items;

export default class MarketBar extends Component {
  state = {
    isMenuShown: false,
    data: [],
  };

  componentDidMount() {
    get_prices().then((res) => {
      const data = res.data.payload;
      this.setState({ data: data });
    });
  }

  render() {
    const data = this.state.data;

    const marketData = OPTIONS.map((ele) => {
      const dataid = ele.value;
      const filteredData = data.filter((item) => item.dataid === dataid);
      if (filteredData.length === 0) {
        return null;
      }
      const country = filteredData[0].country;
      const region = filteredData[0].region;
      const weight = filteredData[0].weight;
      const lastprice = filteredData[filteredData.length - 1]["price_usd"];
      const wow =
        filteredData[filteredData.length - 1]["price_usd"] - filteredData[filteredData.length - 2]["price_usd"];
      return {
        dataid,
        country,
        region,
        weight,
        lastprice,
        wow,
        filteredData,
      };
    }).filter((ele) => ele);

    const generateChart = (selectedData) => {
      return (
        <Chart
          type="line"
          // width={"100%"}
          height={"200"}
          options={{
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    display: true,
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    // display: ,
                  },
                  ticks: {
                    // display: false,
                  },
                },
              ],
            },
          }}
          data={{
            labels: selectedData.map((ele) => ele.date),
            datasets: [
              {
                fill: true,
                borderColor: "#553709",
                // backgroundColor: 'rgba(75,192,192,0.4)',
                data: selectedData.map((ele) => ele.price_usd),
              },
            ],
          }}
        />
      );
    };

    return (
      <Layout>
        <WidgetTitle text={TITLE} />
        <div className="market-wrapper">
          {marketData.map((item, idx) => {
            return (
              <div key={idx} className="market-card">
                <div style={{ fontSize: "20px" }}>
                  <b>{item.country}</b>{" "}
                </div>
                <div style={{ fontSize: "12px" }}>{item.region}</div>
                <div style={{ fontSize: "12px" }}>{item.weight} HOSO/kg</div>
                <div>
                  <b>${item.lastprice.toFixed(2)}</b> USD/kg
                </div>
                <div style={{ fontSize: "15px" }}>
                  WOW: <span style={{ color: item.wow >= 0 ? "green" : "red" }}>${item.wow.toFixed(2)}</span>
                </div>
                <div className="chart-wrapper">{generateChart(item.filteredData)}</div>
              </div>
            );
          })}
        </div>
      </Layout>
    );
  }
}
